.settingsApp {
  --bg: #f3f3f3;
  --txt_clr-rgb: 0 0 0;

  background: var(--fakeMica);
  color: rgb(var(--txt_clr-rgb));
  min-width: 720px;
}

.settingsApp .restWindow {
  font-size: 14px;
  height: 100%;

  // container: inline-size / appWrapper;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  nav {
    position: absolute;
    left: 0;
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .nav_top {
      .account {
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 8px;
        margin: 8px;

        &:hover {
          background: rgb(var(--txt_clr-rgb) / 3.33%);
        }

        div p {
          &:first-child {
            font-weight: 500;
          }
          &:last-child {
            font-size: smaller;
          }
        }
      }

      .search {
        background: white;
        height: 32px;
        width: calc(300px - 32px);
        border: 0;
        border-bottom: 1px solid var(--clrPrm);
        border-radius: 4px;
        padding: 8px;
        margin: 8px 16px 20px;

        &:hover {
          background: rgb(255 255 255 / 33%);
        }
        &:focus {
          border-bottom: 2px solid var(--clrPrm);
        }
        &:focus-visible {
          outline: none;
        }

        &::placeholder {
          color: rgb(var(--txt_clr-rgb) / 66%);
        }
      }
    }

    .nav_bottom {
      display: flex;
      flex-direction: column;
      overflow: overlay;
      position: relative;

      .navLink {
        position: relative;
        overflow: hidden;
        height: 36px;
        padding-left: 7px;
        margin: 2px 16px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        flex-shrink: 0;

        &:hover,
        &.selected {
          background: rgb(var(--txt_clr-rgb) / 5%);
        }

        img {
          margin: 0 10.5px 0 7px;
        }
      }

      .marker {
        position: absolute;
        background: var(--clrPrm);
        height: 16px;
        width: 3px;
        border-radius: 3px;
        top: 0;
        left: 16px;
        transform: translateY(12px);
        transition: transform 250ms cubic-bezier(1, 0, 0, 1);
      }

      @for $i from 1 to 12 {
        .navLink:nth-child(#{$i}).selected ~ .marker {
          transform: translateY(((40 * ($i - 1)) + 12) + px);
        }
      }
    }
  }

  main {
    margin-left: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .sysTop {
      width: min(100%, 1000px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 8px 0 14px;
      gap: 8px;

      .left {
        display: flex;
        align-items: center;

        .device_img {
          height: 70px;
          border: 4px solid black;
          border-radius: 4px;
        }
        .column_device {
          margin-left: 16px;

          .device_name {
            font-size: 18px;
            font-weight: 500;
          }
          .device_model {
            color: rgb(var(--txt_clr-rgb) / 90%);
          }
          .device_rename {
            color: var(--clrPrm);
          }
        }
      }

      .right {
        display: flex;

        .column {
          display: flex;
          align-items: center;
          border-radius: 4px;
          padding: 8px;
          &:hover {
            background: rgb(var(--txt_clr-rgb) / 5%);
          }

          p {
            font-weight: 500;
            padding: 0 12px;

            .column_lower {
              color: rgb(var(--txt_clr-rgb) / 60%);
              font-size: 13px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .netTop {
      width: min(100%, 1000px);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      gap: 1rem;

      div:first-child {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .box {
        display: flex;
        padding: 8px;
        gap: 10px;
        align-items: center;
        border-radius: 4px;

        .settingsIcon {
          margin: 0;
        }

        &:hover {
          background: rgb(var(--txt_clr-rgb) / 5%);
        }

        h3 {
          font-weight: 500;
          font-size: 14px;
        }
        p {
          font-size: 12px;
          color: rgb(var(--txt_clr-rgb) / 67%);
        }
      }
    }

    .personaliseTop {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      gap: 12px;

      .mainImg {
        height: 200px;
        border: 8px solid black;
        border-radius: 1rem;
        margin-right: 32px;
      }

      div {
        h3 {
          font-size: 14px;
          font-weight: 500;
          padding-bottom: 8px;
        }
        .bgBox {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);
          gap: 8px;

          img {
            width: 120px;
            border-radius: 4px;
          }

          .selected {
            box-shadow: 0 0 0 2px var(--bg), 0 0 0 4px var(--clrPrm);
            border-radius: 2px;
          }
        }
      }
    }

    .accountsTop {
      display: flex;
      gap: 16px;
      align-items: center;
      margin: 1rem 0;

      div p {
        &:first-child {
          font-size: 16px;
        }
        &:nth-child(2),
        &:last-child {
          color: rgb(var(--txt_clr-rgb) / 67%);
        }
      }
    }

    .langSwitcherTile {
      &::after {
        display: none;
      }

      select {
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid rgb(238, 236, 235);
        border-bottom: 1px solid rgb(212, 211, 210);
        color: rgb(var(--txt_clr-rgb));
        height: 32px;
        padding-left: 8px;
        border-radius: 6px;
      }
    }

    .updateTop {
      display: flex;
      width: min(100%, 1000px);
      align-items: center;
      gap: 1rem;

      .btn {
        background: var(--clrPrm);
        color: var(--alt-txt);
        padding: 6px 12px;
        border-radius: 4px;
      }

      .left {
        display: flex;
        align-items: center;
        gap: 1rem;

        div {
          h2 {
            font-size: 18px;
            font-weight: 600;
          }
          p {
            font-size: 14px;
            color: rgb(var(--txt_clr-rgb) / 67%);
          }
        }
      }
      .right {
        flex-shrink: 0;
        margin-left: auto;
      }
    }

    h1 {
      padding: 14px 24px;
      font-weight: 500;
      font-size: 28px;
    }

    .tilesCont {
      padding: 0 24px 44px;
      overflow-y: overlay;
      display: flex;
      flex-direction: column;
      gap: 4px;
      animation: slideUp 0.2s;

      .settingsIcon {
        font-size: 20px;
        margin: 0 21px 0 17.5px;
        font-family: SettingsIcons;
      }

      .tile {
        width: min(100%, 1000px);
        min-height: 67px;
        display: flex;
        align-items: center;
        background: rgb(255 255 255 / 67%);
        border-radius: 4px;
        padding: 7px 42px 7px 0;
        border: 1px solid rgb(0 0 0 / 10%);
        position: relative;

        &:hover {
          background: rgb(255 255 255 / 33%);
        }
        &::after {
          content: ">";
          transform: scaleY(2);
          position: absolute;
          right: 16px;
        }

        .tile_content {
          flex-grow: 1;
        }
        .tile_desc {
          color: rgb(var(--txt_clr-rgb) / 66%);
          font-size: 12.33px;
        }

        &.square {
          width: 220px;
          height: 220px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          padding: 0;
          font-weight: 500;

          .settingsIcon {
            font-size: 48px;
          }
        }

        &.thin-blue {
          min-height: unset;
          height: unset;
          color: var(--clrPrm);
          justify-content: center;
          margin: 4px 0;
        }

        &.thin-blue::after,
        &.square::after {
          display: none;
        }
      }

      .subHeading {
        font-weight: 500;
        height: 45px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }

      .spacer {
        padding: 8px;
      }
    }
  }

  .navMenuBtn {
    display: none;
  }

  // @container appWrapper size(max-width: 800px) {
  //   .navMenuBtn {
  //     display: flex;
  //     position: absolute;
  //     top: 8px;
  //     right: 8px;
  //     padding: 12px;
  //     border-radius: 6px;

  //     &:hover {
  //       background: rgb(var(--txt_clr-rgb) / 5%);
  //     }

  //     svg {
  //       transition: all 0.1s;
  //     }
  //     &:active svg {
  //       transform: scaleX(0.67);
  //     }
  //   }

  //   main {
  //     margin-left: 0;
  //   }
  //   nav {
  //     background: var(--bg);
  //     z-index: 999;
  //     transform: translateX(-100%);
  //     transition: all 0.2s cubic-bezier(0, 0, 0, 1);

  //     &.open {
  //       transform: translateX(0);
  //     }
  //   }
  // }
}
body[data-theme="dark"] .settingsApp {
  --bg: #202020;
  --txt_clr-rgb: 255 255 255;

  .restWindow {
    nav .nav_top .search {
      background: rgb(255 255 255 / 3.33%);

      &:hover {
        background: rgb(255 255 255 / 5%);
      }
    }

    main .tilesCont .tile {
      background: rgb(255 255 255 / 5%);

      &:hover {
        background: rgb(255 255 255 / 10%);
      }
      select {
        background: rgb(255 255 255 / 7%);
        border: 1px solid rgba(238, 236, 235, 0.071);
        border-bottom: 1px solid rgba(212, 211, 210, 0.051);

        option {
          background: var(--bg);
        }
      }
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(70px);
  }
  to {
    transform: translateY(0);
  }
}
